.toast {
  &--success,
  &--alert,
  &--error {
    .mdc-snackbar__surface .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
      color: #ffffff !important;
    }
  }

  &--success {
    .mdc-snackbar__surface {
      background-color: var(--color-success) !important;
    }
  }

  &--alert {
    .mdc-snackbar__surface {
      background-color: var(--color-alert) !important;
    }
  }

  &--error {
    .mdc-snackbar__surface {
      background-color: var(--color-danger) !important;
    }
  }
}