.input {
  position: relative;

  width: 100%;
  display: block;

  transition-duration: 0.2s;
  transition-property: margin-bottom;

  &__icon {
    opacity: 0.2;
  }

  &.ng-invalid.ng-touched {
    margin-bottom: 10px;
  }

  .mdc-notched-outline__notch {
    border-right: none;
  }

  &--password {
    position: relative;

    .input__reveal {
      display: flex;

      position: absolute;
      right: 0;
      top: 50%;

      color: #c3c3c3;
      transform: translateY(-50%);
    }

    .input__field {
      padding-right: 34px;
    }
  }

  &--compact:not(&--select) {
    --height: 45px;
    --border-radius: 5px;

    height: var(--height) !important;

    .mdc-floating-label.mat-mdc-floating-label {
      font-size: 14px;
    }

    .mat-mdc-form-field-flex,
    .mat-mdc-form-field-infix {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      height: var(--height) !important;

      .mdc-notched-outline__leading,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing {
        height: 100% !important;
      }

      .mdc-notched-outline__leading {
        border-top-left-radius: var(--border-radius) !important;
        border-bottom-left-radius: var(--border-radius) !important;
      }

      .mdc-notched-outline__trailing {
        border-top-right-radius: var(--border-radius) !important;
        border-bottom-right-radius: var(--border-radius) !important;
      }

      .mat-mdc-input-element {
        position: relative;
        top: 9px;
        font-size: 14px;
      }

      .mdc-floating-label {
        transform: var(--mat-mdc-form-field-label-transform,
            translateY(-15px) translateX(calc(1 * (52px + var(--mat-mdc-form-field-label-offset-x, 0px))))) !important;
      }
    }

    .mat-mdc-text-field-wrapper {
      background-color: #ffffff;
    }

    &.input--password .input__reveal {
      transform: translateY(-68%);
    }
  }

  &--secondary {
    --color: hsl(var(--color-text-hsl), 70%);
    --border-color: hsl(var(--color-text-hsl), 90%);

    .mat-mdc-form-field-flex,
    .mat-mdc-form-field-infix {

      .mdc-notched-outline__leading,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing {
        border-color: var(--border-color) !important;
      }

      .mdc-floating-label {
        color: var(--color);
      }
    }

    &.mat-focused {
      --color: var(--color-primary);
      --border-color: var(--color-primary);
    }
  }

  &--select {
    cursor: pointer;
    background-color: #ffffff;

    &.input--compact {
      max-height: 45px !important;

      .mat-mdc-text-field-wrapper {
        max-height: 45px !important;
      }

      .mat-mdc-form-field-subscript-wrapper {
        display: none;
      }

      .mdc-floating-label.mat-mdc-floating-label {
        position: relative;
        top: 50%;
      }

      .mat-mdc-select-arrow-wrapper {
        position: relative;
        top: -4px;
      }

      .mat-mdc-select-value-text {
        font-size: 13px;

        position: relative;
        top: -4px;
      }
    }
  }

  &__select-panel {
    &--compact {
      .mdc-list-item {
        font-size: 13px;
      }
    }
  }
}

html.dark {
  .input {

    input::placeholder,
    textarea::placeholder {
      color: #bebebe !important;
    }

    .mat-mdc-text-field-wrapper {
      background-color: var(--color-dark);
      border-color: rgb(30 41 59 / var(--tw-bg-opacity)) !important;
    }

    .mdc-floating-label.mat-mdc-floating-label {
      color: hsl(var(--color-secondary-hsl), 70%);
    }

    &__icon {
      color: hsl(var(--color-secondary-hsl), 70%);
    }

    &--compact:not(&--select) {
      .mat-mdc-text-field-wrapper {
        background-color: var(--color-dark);
        border-color: rgb(30 41 59 / var(--tw-bg-opacity)) !important;
      }
    }

    &--secondary {
      border-color: rgb(30 41 59 / var(--tw-bg-opacity)) !important;

      .mat-mdc-form-field-flex,
      .mat-mdc-form-field-infix {

        .mdc-notched-outline__leading,
        .mdc-notched-outline__notch,
        .mdc-notched-outline__trailing {
          border-color: rgb(30 41 59 / var(--tw-bg-opacity)) !important;
        }
      }

      &.mat-focused {
        color: var(--color-primary);
        border-color: var(--color-primary) !important;
      }
    }

    &--select {
      background-color: var(--color-dark);
      border-color: var(--color-primary) !important;
    }
  }

  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
    color: hsl(var(--color-secondary-hsl), 70%);
  }

  .mat-mdc-select-arrow {
    color: hsl(var(--color-secondary-hsl), 70%);
  }

  .mat-mdc-select-value {
    color: hsl(var(--color-secondary-hsl), 70%);
  }

  .mdc-menu-surface.mat-mdc-select-panel {
    background-color: var(--color-dark);
  }

  .mat-mdc-form-field-icon-prefix>.mat-icon,
  .mat-mdc-form-field-icon-suffix>.mat-icon {
    color: hsl(var(--color-secondary-hsl), 70%) !important;
  }

  .mdc-menu-surface.mat-mdc-autocomplete-panel.mat-mdc-autocomplete-visible {
    background-color: var(--color-dark);
  }

  .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
    border-color: white !important;
  }
  
  .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:disabled~.mdc-checkbox__background{
    border-color: rgba(255, 255, 255, 0.38) !important;
  }

}