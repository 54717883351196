.button {
  position: relative;

  transition-duration: 0.2s;
  transition-property: padding;

  &__loader {
    display: none !important;
  }

  &--success {
    --mdc-filled-button-container-color: var(--color-success) !important;
  }

  &--danger {
    --mdc-filled-button-container-color: var(--color-danger) !important;
  }

  &--compact {
    .button__icon {
      margin: 0;
      opacity: 0;
      width: 0 !important;

      transition-duration: 0.2s;
      transition-property: width opacity margin-left;
    }

    &:hover {
      .button__icon {
        opacity: 1;
        margin-right: 5px;
        width: 1.2rem !important;
      }
    }
  }

  &--import {
    overflow: hidden !important;

    .button__trigger {
      cursor: pointer;

      position: absolute;
      left: 0;
      top: 0;

      width: 100%;
      height: 100%;

      opacity: 0;
      transform: scale(10);
    }
  }

  &--loading {
    .mdc-button__label {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .button__loader {
      display: block !important;
      margin-right: 10px;

      width: 18px !important;
      height: 18px !important;

      opacity: 0.3;
      filter: brightness(0);
    }

    .button__icon {
      display: none !important;
    }
  }
}

.dark {
  .mat-mdc-fab[disabled],
  .mat-mdc-mini-fab[disabled],
  .mat-mdc-button[disabled],
  .mat-mdc-unelevated-button[disabled],
  .mat-mdc-raised-button[disabled],
  .mat-mdc-outlined-button[disabled] {
    color: var(--color-tertiary) !important;
    background-color: lightslategray !important;
  }

  .mat-mdc-button:not(:disabled) {
    color: var(--color-tertiary) !important;
  }
}
