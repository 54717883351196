.dialog {
  max-width: 100%;

  &__head {
    color: var(--color-text);
    background: linear-gradient(
      to top right,
      rgba(var(--color-primary-rgb), 0.1),
      transparent
    );

    padding-left: 15px;
    padding-right: 15px;
    margin: 0 !important;
  }

  &__body {
    font-size: 14px;
    padding-top: 15px !important;
    padding-bottom: 35px !important;
    color: var(--color-text);

    p {
      margin: 0;
      padding: 0;
    }
  }

  &__foot {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end !important;
    border-top: 1px solid #efefef;
  }
}

.dark {
  .dialog {
    background: var(--color-dark) !important;

    &__head {
      padding-bottom: 18px !important;
      color: var(--color-tertiary) !important;
      background: hsl(var(--color-dark-hsl), 13%) !important;
    }

    &__body {
      color: var(--color-tertiary);
      background: var(--color-dark-gray) !important;
    }

    &__foot {
      border-top: 1px solid var(--color-dark) !important;
      background: var(--color-dark-gray) !important;
    }
  }
}
