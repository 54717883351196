a.link {
  position: relative;
  color: #579efc;

  &::before {
    content: '';

    position: absolute;
    left: 0;
    top: 0;

    width: 0;
    height: 100%;

    background-color: #579efc29;

    transition-duration: 0.2s;
    transition-property: width;
  }

  &:hover::before {
    width: 100%;
  }
}