html.dark {
  .body .card--info .permissions {
    .mat-expansion-panel-header-title {
      color: #9fb3c6 !important;
    }

    .mdc-list-item:hover {
      background-color: transparent !important;
    }
  }
}