.role {
  font-size: 12px;
  font-weight: 100;
  text-transform: uppercase;

  color: var(--color-role-user);
  background-color: var(--color-role-user-contrast);

  padding: 4px 8px;
  border-radius: 20px;

  &--ADMIN {
    color: var(--color-role-admin);
    background-color: var(--color-role-admin-contrast);
  }

  &--MAINTAINER {
    color: var(--color-role-maintainer);
    background-color: var(--color-role-maintainer-contrast);
  }
}

html.dark {
  .role {
    color: var(--color-role-user-contrast);
    background-color: rgba(var(--color-role-user-rgb), 0.5);

    &--ADMIN {
      color: var(--color-role-admin-contrast);
      background-color: rgba(var(--color-role-admin-rgb), 0.5);
    }

    &--MAINTAINER {
      color: var(--color-role-maintainer-contrast);
      background-color: rgba(var(--color-role-maintainer-rgb), 0.5);
    }
  }
}