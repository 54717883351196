.active-link {
  background-color: hsl(var(--color-primary-hsl), 95%) !important;

  .mdc-list-item__content > span {
    color: var(--color-primary) !important;
  }
}

.mat-nav-list .mat-list-item:focus {
  background-color: hsl(var(--color-primary-hsl), 95%) !important;
}

mat-nav-list {
  padding: 0px !important;

  a > .mdc-list-item__content {
    span {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }
}

.dark {
  .mdc-list-item__primary-text {
    color: hsl(var(--color-secondary-hsl), 70%) !important;
  }
  .active-link {
    background-color: hsl(var(--color-primary-hsl), 95%) !important;

    .mdc-list-item__content > span {
      color: var(--color-primary) !important;
    }
  }
  .mdc-list-item:hover {
    background-color: hsl(var(--color-primary-hsl), 95%) !important;
    .mdc-list-item__content > span {
      color: hsl(var(--color-dark-hsl), 95%) !important;
    }
    .mdc-list-item__primary-text {
      color: hsl(var(--color-dark-hsl), 95%) !important;
    }
  }
}
