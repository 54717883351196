:root {
  --color-primary: #1963d1;
  --color-primary-rgb: 25, 99, 209;
  --color-primary-hsl: 216, 79%;

  --color-secondary: #fafbfc;
  --color-secondary-rgb: 250, 251, 252;
  --color-secondary-hsl: 210, 25%;

  --color-tertiary: #f8fafc;
  --color-tertiary-rgb: 248, 250, 252;
  --color-tertiary-hsl: 210, 40%;

  --color-dark: #1e293b;
  --color-dark-rgb: 30, 41, 59;
  --color-dark-hsl: 217, 33%;

  --color-success: #69bd30;
  --color-success-rgb: 105, 189, 48;
  --color-success-hsl: 96, 59%;

  --color-alert: #cbd147;
  --color-alert-rgb: 203, 209, 71;
  --color-alert-hsl: 63, 60%;

  --color-danger: #e34e4e;
  --color-danger-rgb: 227, 78, 78;
  --color-danger-hsl: 0, 73%;

  --color-white: #ffffff;
  --color-white-rgb: 255, 255, 255;
  --color-white-hsl: 0, 0%;

  --color-text: #636987;
  --color-text-rgb: 99, 105, 135;
  --color-text-hsl: 230, 15%;

  --color-role-user: #747474;
  --color-role-user-rgb: 116, 116, 116;
  --color-role-user-hsl: 0, 0%;
  --color-role-user-contrast: hsl(var(--color-role-user-hsl), 94%);

  --color-role-admin: #ff4444;
  --color-role-admin-rgb: 255, 68, 68;
  --color-role-admin-hsl: 0, 100%;
  --color-role-admin-contrast: hsl(var(--color-role-admin-hsl), 94%);

  --color-role-maintainer: #168f00;
  --color-role-maintainer-rgb: 22, 143, 0;
  --color-role-maintainer-hsl: 111, 100%;
  --color-role-maintainer-contrast: hsl(var(--color-role-maintainer-hsl), 94%);

  --color-light-dark: #0f172a;
  --color-light-dark-rgb: 15, 23, 42;
  --color-light-dark-hsl: 222, 47%, 11%, 1;

  --color-dark-gray: #1e293b;
  --color-dark-gray-rgb: 30, 41, 59;
  --color-dark-gray-hsl: 217, 33%, 17%, 1;
}
