.dark {
  .mat-mdc-menu-content {
    background-color: var(--color-dark) !important;
  }
  .mat-mdc-menu-item:hover:not([disabled]),
  .mat-mdc-menu-item.cdk-program-focused:not([disabled]),
  .mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
  .mat-mdc-menu-item-highlighted:not([disabled]) {
    background-color: var(--color-dark-gray) !important;
  }
}
