.dark {
  .mat-mdc-table {
    .mat-mdc-cell {
      color: var(--color-tertiary);
    }
  }
  .mat-mdc-table tbody,
  .mat-mdc-table tfoot,
  .mat-mdc-table thead,
  .mat-mdc-cell,
  .mat-mdc-footer-cell,
  .mat-mdc-header-row,
  .mat-mdc-row,
  .mat-mdc-footer-row,
  .mat-mdc-table .mat-mdc-header-cell {
    color: var(--color-tertiary);
  }

  // .mat-mdc-table tfoot,
  // .mat-mdc-table thead {
  //   background: var(--color-dark-gray) !important;
  // }
  .mat-mdc-table tfoot {
    background: var(--color-dark-gray) !important;
  }
}
