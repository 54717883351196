.item-card {
  height: 140px;
  padding: 18px;
  position: relative;
  border-radius: 10px;
  background-color: var(--color-tertiary);

  flex-direction: column;
  display: flex !important;

  &::after {
    content: "";
    z-index: 1;

    position: absolute;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 40%;

    background-image: linear-gradient(
      to top,
      hsl(var(--color-primary-hsl), 99%) 70%,
      transparent
    );
  }

  &:hover {
    background-image: linear-gradient(
      to right top,
      hsl(var(--color-primary-hsl), 95%),
      transparent
    );

    &::after {
      background-image: linear-gradient(
        to top,
        hsl(var(--color-primary-hsl), 96%) 70%,
        transparent
      );
    }
  }

  &__actions {
    z-index: 2;

    display: flex;
    align-items: center;
    justify-content: right;

    position: absolute;
    bottom: 8px;
    right: 10px;
  }

  &__action {
    opacity: 0.3;
    --mdc-icon-button-icon-color: hsl(var(--color-text-hsl), 80%);

    transition-duration: 0.2s;
    transition-property: color opacity;

    &--show:hover {
      --mdc-icon-button-icon-color: var(--color-primary);
    }

    &--edit:hover,
    &--download:hover {
      --mdc-icon-button-icon-color: var(--color-success);
    }

    &--clone:hover {
      --mdc-icon-button-icon-color: var(--color-alert);
    }

    &--delete:hover {
      --mdc-icon-button-icon-color: var(--color-danger);
    }
  }

  &__title {
    font-size: 16px;
    font-weight: 400;

    color: var(--color-text);
  }

  &__description {
    margin: 0 !important;

    font-weight: 300;
    color: hsl(var(--color-text-hsl), 70%);
  }

  &__chip {
    padding: 2px 8px;
    border-radius: 10px;

    font-size: 11px;
    font-weight: 100;

    background-color: #eaedf2;

    position: relative;
    top: -2px;
  }

  &:hover {
    .item-card__action {
      opacity: 1;
    }
  }
}

html.dark {
  .item-card {
    background-color: hsl(var(--color-dark-hsl), 15%);

    &::after {
      background-image: linear-gradient(
        to top,
        hsl(var(--color-dark-hsl), 15%) 70%,
        transparent
      );
    }

    &:hover {
      background-image: linear-gradient(
        to right top,
        hsl(var(--color-primary-hsl), 18%),
        transparent
      );

      &::after {
        background-image: linear-gradient(
          to top,
          hsl(var(--color-dark-hsl), 20%) 70%,
          transparent
        );
      }
    }

    &__title {
      color: var(--color-text);
    }

    &__description {
      color: hsl(var(--color-text-hsl), 70%);
    }

    &__chip {
      background-color: hsl(var(--color-dark-hsl), 10%);
    }
  }
}
