.tabs {
  .mat-mdc-tab-list {
    background-color: #f3f4f6;

    .mat-mdc-tab-links {
      margin: auto;

      width: 400px;
      max-width: 100%;

      ion-icon {
        margin-right: 5px;
        font-size: 24px;
      }
    }
  }
}

html.dark {
  .tabs {
    .mat-mdc-tab-list {
      background-color: #0b111f;

      .mat-mdc-tab-link {
        .mdc-tab__text-label {
          color: #ffffff;
        }
      }
    }
  }
  .mat-mdc-tab .mdc-tab__text-label,
  .mat-mdc-tab-link .mdc-tab__text-label {
    color: #ffffff;
  }
}
