html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--color-text);
}

.header {
  background-color: var(--color-tertiary);
}

.title {
  margin: 0 !important;
  color: var(--color-text) !important;

  display: flex;
  align-items: center;

  &__wrapper {
    display: flex;
    flex-direction: column;
    line-height: 20px;
  }

  &__parent {
    color: hsl(var(--color-text-hsl), 60%);
    font-weight: 100;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    width: 100%;
    max-width: calc(100vw - 550px);

    @media screen and (max-width: 600px) {
      max-width: calc(100vw - 213px);
    }
  }

  .button--back {
    margin-right: 5px;
    color: var(--color-text);
  }
}
.dark {
  body {
    background-color: var(--color-dark);
  }
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--color-secondary);
  }

  .title {
    color: var(--color-tertiary) !important;
  }
}
